import React from "react";

// Hooks
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

// Utils
import { getImpersonationMode } from "../../utils/auth";
import { MODE_SWITCHING_URL } from "../../utils/constants";
import { MESSAGES } from "../../utils/message";

// MUI Components
import { Box, Typography } from "@mui/material";

// Icons
import ModeTick from "../../assets/images/General/mode-tick.svg";

// Styles
import "./styles.scss";

const ModeReminder = () => {
  const currentMode = useSelector((state) =>
    state.userReducers.user.isLiveMode === true
      ? "Live"
      : state.userReducers.user.isLiveMode === false
      ? "Test"
      : null
  );
  const isImpersonatedMode = getImpersonationMode() || false;

  return (
    <>
      {currentMode === "Test" && (
        <Box className={`modeReminderWrapper ${!currentMode === "Test" && "slideAway"}`} 
        sx={{
          top: isImpersonatedMode ? "18px" : "-10px"
        }}>
          <Box className="modeReminderContent">
            <img src={ModeTick} alt="mode-tick" />
            <Typography>{MESSAGES.MODE_REMINDER.TITLE}</Typography>
            <Link to={MODE_SWITCHING_URL} target="_blank">{MESSAGES.MODE_REMINDER.LINK_MESSAGE}</Link>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ModeReminder;
