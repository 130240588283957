import { clearStorage } from "./local-storage";

// icons
import Account from "../assets/images/header/account.svg";
import Integration from "../assets/images/header/integration.svg";
import Settings from "../assets/images/header/settings.svg";
import Logout from "../assets/images/header/logout.svg";
import Mailer from "../assets/images/create-orders/mailer.svg";
import Contacts from "../assets/images/create-orders/contacts.svg";
import Details from "../assets/images/create-orders/order-details.svg";
import Review from "../assets/images/create-orders/review-order.svg";
import Mailings from "../assets/images/dashboard/email-icon.svg";
import Spending from "../assets/images/dashboard/spending-icon.svg";
import Sent from "../assets/images/dashboard/sent.svg";
import Transit from "../assets/images/dashboard/transit.svg";
import Deliver from "../assets/images/dashboard/delievered.svg";
import Returned from "../assets/images/dashboard/returned.svg";
import ClientDashboard from "../assets/images/admin/web.svg";
import AdminDashboard from "../assets/images/admin/adminpanel.svg";

import { setDashboardMode } from "./auth";

export const INDUSTRY_OPTIONS = [
  {
    id: 1,
    title: "Real Estate",
  },
  {
    id: 2,
    title: "Finance",
  },
  {
    id: 3,
    title: "Insurance",
  },
  {
    id: 4,
    title: "Automobile Sales",
  },
  {
    id: 5,
    title: "Healthcare",
  },
  {
    id: 6,
    title: "Ecommerce",
  },
  {
    id: 7,
    title: "Other",
  },
];

export const COMPANY_OPTIONS = [
  {
    id: 1,
    title: "1-5",
  },
  {
    id: 2,
    title: "6-10",
  },
  {
    id: 3,
    title: "11-25",
  },
  {
    id: 4,
    title: "26-100",
  },
  {
    id: 5,
    title: "100+",
  },
];

export const ROLE_OPTIONS = [
  {
    id: 1,
    title: "Business Owner",
  },
  {
    id: 2,
    title: "Executive",
  },
  {
    id: 3,
    title: "Marketing Manager",
  },
  {
    id: 4,
    title: "Admin Assistant",
  },
  {
    id: 5,
    title: "Other",
  },
];

export const ABOUT_OPTIONS = [
  {
    id: 1,
    title: "Facebook",
  },
  {
    id: 2,
    title: "Instagram",
  },
  {
    id: 3,
    title: "Tiktok",
  },
  {
    id: 4,
    title: "Google/Bing",
  },
  {
    id: 5,
    title: "Friend",
  },
  {
    id: 6,
    title: "Podcast",
  },
  {
    id: 7,
    title: "LinkedIn",
  },
  {
    id: 8,
    title: "Youtube",
  },
  {
    id: 9,
    title: "Other",
  },
];

export const GENERAL_NAVIGATION = [
  { id: 1, title: "Overview", link: "/overview", childrens: [] },
  { id: 2, title: "Orders", link: "/orders", childrens: [] },
  {
    id: 3,
    title: "Templates",
    link: "/templates",
    childrens: ["/create-template", "/template-builder", "/template-html"],
  },
  { id: 4, title: "Contacts", link: "/contacts", childrens: [] },
];

export const ADMIN_NAVIGATION = [
  { id: 1, title: "Overview", link: "/admin/overview", childrens: [] },
  { id: 2, title: "Orders", link: "/admin/orders", childrens: [] },
  { id: 3, title: "Users", link: "/admin/users", childrens: [] },
  { id: 4, title: "Batches", link: "/admin/batches", childrens: [] },
  { id: 5, title: "Categories", link: "/admin/categories", childrens: [] },
];

export const DEFAULT_PAGINATION = {
  page: 1,
  pageSize: 20,
};

export const DEFAULT_PAGINATION_WITHOUT_PAGE_SIZE = {
  page: 1,
  pageSize: 10,
};

export const EVENT_TYPES = [
  { id: 1, title: "All" },
  { id: 2, title: "Created" },
  { id: 3, title: "Updated" },
  // { id: 4, title: 'Failed' },
  // { id: 5, title: 'Successful' },
];

export const DEFAULT_PAGE_SIZES = [20, 50, 100];

export const ADDRESS_STATUS = [
  { id: 1, title: "Corrected" },
  { id: 2, title: "Failed" },
  { id: 3, title: "Verified" },
];

export const MAILED_STATUS = [
  {
    id: 1,
    title: "Not Mailed",
  },
  {
    id: 2,
    title: "Scheduled",
  },
  {
    id: 3,
    title: "Mailed",
  },
  {
    id: 4,
    title: "In Transit",
  },
  {
    id: 5,
    title: "Delivered",
  },
  {
    id: 6,
    title: "Returned to Sender",
  },
];

export const dragDrop = {
  baseStyle: {},
  focusedStyle: {
    border: "1.5px solid #ED5C2F",
  },
  acceptStyle: {
    border: "1.5px solid #03A155",
  },
  rejectStyle: {
    border: "1.5px solid #F00",
  },
};

export const SAMPLE_CSV = `First Name,Last Name,Company,Address Line 1,Address Line 2,City,State,Zip Code,Phone Number,Email Address
Alia Test,Walter Test,Dooley,19021 Adalberto Prairie,Suite 253,North Columbus,Nebraska,24604,208-495-6500,Nichole.OConnell21@gmail.com
Greyson Test,Allen Test,Mertz LLC,2724 Erna Knolls,Suite 024,Mafaldaton,Washington,17482,997-670-2200,Cathy26@yahoo.com`;

export const ROLES = {
  ORGANIZATION_ADMIN: "organization-admin",
  ORGANIZATION_USER: "organization-user",
};

export const MODE_SWITCHING_URL = "https://help.openletterconnect.com/getting-support/account-settings/api-keys#open-letter-connect-test-and-live-mode";

export const Barcode =
  "https://api.openletterconnect.com/api/v1/download/s3/openletterconnect/assets/preview/one-barcode.png";
export const TERMS_URL = "https://openletterconnect.com/terms/";
export const HELP_CENTER_URL =
  "https://help.openletterconnect.com/getting-support";
export const GENERAL_SETTINGS_LEARN_URL =
  "https://help.openletterconnect.com/getting-support/account-settings/general";
export const PRODUCT_LEARN_URL =
  "https://help.openletterconnect.com/getting-support/designing-your-mailers/olc-mailing-templates";
export const ENVELOPE_LEARN_URL =
  "https://help.openletterconnect.com/getting-support/designing-your-mailers/envelope-options";
export const GOOGLE_STREET_VIEW_IMAGE_URL = "https://api.openletterconnect.com/api/v1/download/s3/openletterconnect/default_images/dummyGSV.png";

export const USER_ROLES = [
  {
    id: 1,
    title: "Admin",
    value: ROLES.ORGANIZATION_ADMIN,
  },
  {
    id: 2,
    title: "User",
    value: ROLES.ORGANIZATION_USER,
  },
];

export const USER_STATUS = {
  PENDING: "Pending",
  APPROVED: "Active",
  BLOCKED: "Blocked",
  INACTIVE: "InActive",
  INVITED: "Invite Pending",
};

export const HEADERS_DROPDOWN = [
  {
    id: 1,
    name: "Account",
    image: Account,
    func: (handleAfterClick) => {
      handleAfterClick("/account/profile");
    },
  },
  {
    id: 2,
    name: "Settings",
    image: Settings,
    func: (handleAfterClick) => {
      handleAfterClick("/settings/general");
    },
  },
  {
    id: 3,
    name: "Admin Portal",
    image: AdminDashboard,
    func: (handleAfterClick) => {
      setDashboardMode(true);
      handleAfterClick("/admin/overview");
    },
  },
  {
    id: 4,
    name: "Logout",
    image: Logout,
    func: (handleAfterClick) => {
      clearStorage();
      handleAfterClick("/login");
    },
  },
];

export const ADMIN_HEADERS_DROPDOWN = [
  {
    id: 1,
    name: "Back to Account",
    image: ClientDashboard,
    func: (handleAfterClick) => {
      setDashboardMode(false);
      handleAfterClick("/overview");
    },
  },
  {
    id: 2,
    name: "Logout",
    image: Logout,
    func: (handleAfterClick) => {
      clearStorage();
      handleAfterClick("/login");
    },
  },
];

  export const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\\-]).{8,}$/;
export const EMAIL_REGEX = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i;

export const CREATE_ORDER_TABS = [
  {
    id: 1,
    image: Mailer,
    name: "Mailer Details",
  },
  {
    id: 2,
    image: Contacts,
    name: "Add Contacts",
  },
  {
    id: 3,
    image: Details,
    name: "Order Details",
  },
  {
    id: 4,
    image: Review,
    name: "Review Order",
  },
];

export const CONTACTS_OPTION_TYPE = {
  Single: "contactId",
  SingleArray: "contactIds",
  Multiple: "tag",
  Upload: "reqId",
};

export const TRANSACTION_STATUS = {
  Credited: "+",
  Debited: "-",
};

export const MIN_FUNDING_LIMIT = 50;

export const dashboardStats = [
  {
    id: 1,
    title: "Mailed",
    value: "mailed",
    percentage: "mailedRatio",
    image: Sent,
  },
  {
    id: 2,
    title: "In Transit",
    value: "inTransit",
    percentage: "inTransitRatio",
    image: Transit,
  },
  {
    id: 3,
    title: "Delivered",
    value: "delivered",
    percentage: "deliveredRatio",
    image: Deliver,
  },
  {
    id: 4,
    title: "Returned to Sender",
    value: "returnedToSender",
    percentage: "returnedToSenderRatio",
    image: Returned,
  },
];

export const currentCardData = [
  {
    id: 1,
    name: "Total Mailings",
    subTitle: "",
    value: "currentMailings",
    img: Mailings,
  },
  {
    id: 2,
    name: "Total Spend",
    subTitle: "",
    value: "currentSpend",
    img: Spending,
  },
];

export const chartColors = {
  Mailed: "#ED5C2F",
  "In Transit": "#F28230",
  Delivered: "#FAB331",
  "Returned to Sender": "#FFC0B8",
};

export const DASHBOARD_DATE_OPTIONS = [
  {
    id: 1,
    title: "Today",
  },
  {
    id: 2,
    title: "Last 48 hours",
  },
  {
    id: 3,
    title: "Last 7 Days",
  },
  {
    id: 4,
    title: "Last 14 Days",
  },
  {
    id: 5,
    title: "Last 30 Days",
  },
  {
    id: 6,
    title: "Last 90 Days",
  },
  {
    id: 7,
    title: "Last 180 Days",
  },
  {
    id: 8,
    title: "Last Year",
  },
  {
    id: 9,
    title: "Last 2 Years",
  },
];

export const sortOrderForTemplates = [
  "Postcards",
  "Personal Letters",
  "Professional Letters",
  "Bi-Fold Self-Mailers",
  "Tri-Fold Self-Mailers",
  "Real Penned Letter",
];

export const PRODUCT_PRICING_HEADERS = [
  "Features",
  "Starter",
  "Growth",
  "Professional",
  "Enterprise",
];

export const INVOICE_HEADERS = ["Date", "Description", "Amount", "Status"];

export const statesData = [
  {
    state: "Alaska",
    nick: "AK",
    latitude: 64.2008,
    longitude: -149.4937,
  },
  {
    state: "Alabama",
    nick: "AL",
    latitude: 32.8067,
    longitude: -86.7911,
  },
  {
    state: "Arkansas",
    nick: "AR",
    latitude: 34.7465,
    longitude: -92.2896,
  },
  {
    state: "Arizona",
    nick: "AZ",
    latitude: 34.0489,
    longitude: -111.0937,
  },
  {
    state: "California",
    nick: "CA",
    latitude: 35.7783,
    longitude: -118.4179,
  },
  {
    state: "Colorado",
    nick: "CO",
    latitude: 39.5501,
    longitude: -105.7821,
  },
  // {
  //   "state":"Connecticut",
  //   "nick": "CT",
  //   "latitude":41.6032,
  //   "longitude":-72.6851
  // },
  {
    state: "Delaware",
    nick: "DE",
    latitude: 38.9108,
    longitude: -72.5277,
  },
  {
    state: "Florida",
    nick: "FL",
    latitude: 27.9944,
    longitude: -81.7603,
  },
  {
    state: "Georgia",
    nick: "GA",
    latitude: 32.6781,
    longitude: -83.222,
  },
  {
    state: "Hawaii",
    nick: "HI",
    latitude: 20.7984,
    longitude: -156.3319,
  },
  {
    state: "Iowa",
    nick: "IA",
    latitude: 41.878,
    longitude: -93.0977,
  },
  {
    state: "Idaho",
    nick: "ID",
    latitude: 44.0682,
    longitude: -114.742,
  },
  {
    state: "Illinois",
    nick: "IL",
    latitude: 40.6331,
    longitude: -89.3985,
  },
  {
    state: "Indiana",
    nick: "IN",
    latitude: 39.7684,
    longitude: -86.1581,
  },
  {
    state: "Kansas",
    nick: "KS",
    latitude: 38.5267,
    longitude: -96.7265,
  },
  {
    state: "Kentucky",
    nick: "KY",
    latitude: 37.8223,
    longitude: -85.7682,
  },
  {
    state: "Louisiana",
    nick: "LA",
    latitude: 30.9843,
    longitude: -91.9623,
  },
  // {
  //   "state":"Massachusetts",
  //   "nick": "MA",
  //   "latitude":42.4072,
  //   "longitude":-71.3824
  // },
  {
    state: "Maryland",
    nick: "MD",
    latitude: 39.0458,
    longitude: -76.6413,
  },
  {
    state: "Maine",
    nick: "ME",
    latitude: 45.2538,
    longitude: -69.4455,
  },
  {
    state: "Michigan",
    nick: "MI",
    latitude: 44.3148,
    longitude: -85.6024,
  },
  {
    state: "Minnesota",
    nick: "MN",
    latitude: 46.7296,
    longitude: -94.6859,
  },
  {
    state: "Missouri",
    nick: "MO",
    latitude: 38.3566,
    longitude: -92.458,
  },
  {
    state: "Mississippi",
    nick: "MS",
    latitude: 32.3547,
    longitude: -89.3985,
  },
  {
    state: "Montana",
    nick: "MT",
    latitude: 46.8797,
    longitude: -110.3626,
  },
  {
    state: "North Carolina",
    nick: "NC",
    latitude: 35.7596,
    longitude: -79.0193,
  },
  {
    state: "North Dakota",
    nick: "ND",
    latitude: 47.5515,
    longitude: -101.002,
  },
  {
    state: "Nebraska",
    nick: "NE",
    latitude: 41.4925,
    longitude: -99.9018,
  },
  {
    state: "New Hampshire",
    nick: "NH",
    latitude: 43.1939,
    longitude: -71.5724,
  },
  // {
  //   "state":"New Jersey",
  //   "nick": "NJ",
  //   "latitude":40.0583,
  //   "longitude":-74.4057
  // },
  {
    state: "New Mexico",
    nick: "NM",
    latitude: 34.9727,
    longitude: -105.0324,
  },
  {
    state: "Nevada",
    nick: "NV",
    latitude: 38.8026,
    longitude: -116.4194,
  },
  {
    state: "New York",
    nick: "NY",
    latitude: 43.2994,
    longitude: -74.2179,
  },
  {
    state: "Ohio",
    nick: "OH",
    latitude: 40.4173,
    longitude: -82.9071,
  },
  {
    state: "Oklahoma",
    nick: "OK",
    latitude: 35.5653,
    longitude: -96.9289,
  },
  {
    state: "Oregon",
    nick: "OR",
    latitude: 43.8041,
    longitude: -120.5542,
  },
  {
    state: "Pennsylvania",
    nick: "PA",
    latitude: 40.5908,
    longitude: -77.2098,
  },
  {
    state: "Rhode Island",
    nick: "RI",
    latitude: 41.5801,
    longitude: -69.4774,
  },
  {
    state: "South Carolina",
    nick: "SC",
    latitude: 33.8361,
    longitude: -81.1637,
  },
  {
    state: "South Dakota",
    nick: "SD",
    latitude: 44.4443,
    longitude: -100.2263,
  },
  {
    state: "Tennessee",
    nick: "TN",
    latitude: 35.5175,
    longitude: -86.5804,
  },
  {
    state: "Texas",
    nick: "TX",
    latitude: 31.9686,
    longitude: -99.9018,
  },
  {
    state: "Utah",
    nick: "UT",
    latitude: 39.3209,
    longitude: -111.0937,
  },
  {
    state: "Virginia",
    nick: "VA",
    latitude: 37.4316,
    longitude: -78.6569,
  },
  {
    state: "Vermont",
    nick: "VT",
    latitude: 44.5588,
    longitude: -72.5778,
  },
  {
    state: "Washington",
    nick: "WA",
    latitude: 47.0511,
    longitude: -119.7401,
  },
  {
    state: "Wisconsin",
    nick: "WI",
    latitude: 44.7863,
    longitude: -89.8267,
  },
  {
    state: "West Virginia",
    nick: "WV",
    latitude: 38.5976,
    longitude: -80.4549,
  },
  {
    state: "Wyoming",
    nick: "WY",
    latitude: 43.07597,
    longitude: -107.2903,
  },
];
