import React, { useState, useEffect } from "react";

// Validation libraries
import { useFormik } from "formik";
import * as yup from "yup";

// Hooks
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

// Utils
import { MESSAGES } from "../../../../utils/message";
import {
  validateForm,
  realTimeValidation,
  resetValidation,
} from "../../../../utils/formik-validation";

// Actions
import {
  createWebhook,
  fetchOneWebhook,
  fetchWebhookEvents,
  updateWebhook,
} from "../../../../redux/actions/webhooks-actions";
import { success, failure } from "../../../../redux/actions/snackbar-actions";

// Components
import MultiSelect from "../../../General/MultiSelect";
import DeleteWebhookModal from "../DeleteWebhookModal";
import ActionModal from "../ActionModal";

// MUI Components
import {
  Box,
  Container,
  Typography,
  Grid,
  Divider,
  Button,
  InputLabel,
  Input,
  Tooltip,
  CircularProgress,
} from "@mui/material";

// Icon
import Info from "../../../../assets/images/webhooks/info.svg";
import Disable from "../../../../assets/images/webhooks/disable.svg";
import Enable from "../../../../assets/images/webhooks/checkmark.svg";
import Trash from "../../../../assets/images/contact-details/trash.svg";

// styles
import "../styles.scss";

const CreateOrEditWebhook = () => {
  const [selectedWebhook, setSelectedWebhook] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState([]);
  const [eventsError, setEventsError] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [actionWebhookModal, setActionWebhookModal] = useState(false);
  const [deleteWebhookModal, setDeleteWebhookModal] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  const eventOptions = useSelector((state) =>
    state.webhooksReducers.events.map((event, index) => ({
      ...event,
      id: index + 1,
    }))
  );

  const dispatch = useDispatch();

  const webhookId = params?.id;

  const validationSchema = yup.object().shape({
    url: yup
      .string()
      .trim()
      .required(MESSAGES.SETTINGS.WEBHOOKS.CREATE.URL_REQUIRED)
      .max(255, MESSAGES.SETTINGS.WEBHOOKS.CREATE.URL_MAX_LENGTH),
    description: yup
      .string()
      .trim()
      .required(MESSAGES.SETTINGS.WEBHOOKS.CREATE.DESCRIPTION_REQUIRED)
      .max(255, MESSAGES.SETTINGS.WEBHOOKS.CREATE.DESCRIPTION_MAX_LENGTH),
    headers: yup
      .string()
      .trim()
      .max(255, MESSAGES.SETTINGS.WEBHOOKS.CREATE.CUSTOM_HEADERS_LENGTH)
      .nullable(true),
  });

  const formik = useFormik({
    initialValues: {
      url: "",
      description: "",
      headers: "",
    },
    validationSchema: validationSchema,
  });

  const getOneWebhook = async (id) => {
    const webbhook = await dispatch(fetchOneWebhook(id));
    if (webbhook.status === 200) {
      setSelectedWebhook(webbhook.data.data);
    }
  };

  const createOrUpdate = async (event) => {
    try {
      event.preventDefault();
      const isValid = validateForm(formik);
      if (!selectedEvent.length) {
        setEventsError(true);
        if (!isValid) {
          return;
        }
        return;
      }
      if (!isValid) {
        return;
      }
      const payload = {
        callbackUrl: formik?.values?.url?.trim(),
        topic: formik?.values?.description?.trim(),
        customHeaders: formik?.values?.headers?.trim(),
        eventTypes: selectedEvent.length
          ? eventOptions
              .filter((event) => selectedEvent.includes(event.title))
              .map((event) => event.event)
          : undefined,
      };
      isEdit ? (payload.id = params.id) : undefined;
      setLoading(true);
      const response = await dispatch(
        isEdit ? updateWebhook(payload) : createWebhook(payload)
      );
      if (response.status === 200) {
        dispatch(success(response.data.message));
        navigate("/settings/webhooks");
      } else {
        dispatch(failure(response?.data?.message));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data?.message));
      return error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const { callbackUrl, topic, customHeaders } = selectedWebhook || {};
    formik.setValues({
      url: callbackUrl,
      description: topic,
      headers: customHeaders,
    });
    if (isEdit && selectedWebhook?.eventTypes?.length) {
      setSelectedEvent(
        eventOptions
          .filter((event) => selectedWebhook.eventTypes.includes(event.event))
          .map((event) => event.title)
      );
    }
  }, [selectedWebhook]);

  useEffect(() => {
    if (selectedEvent) {
      setCurrentEvent(
        eventOptions.find((event) => event.title === selectedEvent[0])
      );
      setEventsError(false);
    }
  }, [selectedEvent]);

  useEffect(() => {
    getOneWebhook(params?.id);
  }, [actionWebhookModal]);

  useEffect(() => {
    if (params?.id?.length) {
      setIsEdit(true);
      getOneWebhook(params?.id);
    }
    Object.keys(formik.initialValues).forEach((fields) => {
      formik.touched[fields] = false;
    });
    dispatch(fetchWebhookEvents());
    return () => {
      formik.handleReset();
      resetValidation();
    };
  }, []);

  return (
    <Box className="createWebhookWrapper">
      <Container maxWidth="xxl">
        <Grid container spacing={0}>
          <Box className="webhooksHeadingWrapper">
            <Box className="createWebhookHeading">
              <Typography>
                {isEdit
                  ? MESSAGES.SETTINGS.WEBHOOKS.CREATE.EDIT_TITLE
                  : MESSAGES.SETTINGS.WEBHOOKS.CREATE.TITLE}
              </Typography>
            </Box>
          </Box>
          {isEdit && (
            <Box className="webhookEditActionBtn">
              <Button
                className="editBtn"
                onClick={() => setActionWebhookModal(true)}
              >
                <img
                  src={selectedWebhook?.status === "Enabled" ? Disable : Enable}
                  alt="disable"
                />
                <Typography>
                  {selectedWebhook?.status === "Enabled"
                    ? MESSAGES.SETTINGS.WEBHOOKS.DETAILS.DISABLE_LABEL
                    : MESSAGES.SETTINGS.WEBHOOKS.DETAILS.ENABLE_LABEL}
                </Typography>
              </Button>
              <Button
                className="delBtn"
                onClick={() => setDeleteWebhookModal(true)}
              >
                <img src={Trash} alt="trash" />
                <Typography>
                  {MESSAGES.SETTINGS.WEBHOOKS.DETAILS.DELETE_BTN_TEXT}
                </Typography>
              </Button>
            </Box>
          )}
          <Grid container sx={{ marginTop: "32px" }}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <form className="createWebhookform" onSubmit={createOrUpdate}>
                <Box className="fieldsWrapper">
                  <Box className="webhookfield">
                    <InputLabel className="formLabel">
                      {MESSAGES.SETTINGS.WEBHOOKS.CREATE.URL_LABEL}
                    </InputLabel>
                    <Input
                      type="text"
                      placeholder={
                        MESSAGES.SETTINGS.WEBHOOKS.CREATE.URL_PLACEHOLDER
                      }
                      autoComplete="off"
                      name="url"
                      onChange={formik.handleChange}
                      className={
                        formik.errors.url && formik.touched.url
                          ? "invalid formInput"
                          : "formInput"
                      }
                      onBlur={
                        realTimeValidation ? formik.handleBlur : undefined
                      }
                      value={formik.values.url}
                      onKeyDown={
                        realTimeValidation ? formik.handleBlur : undefined
                      }
                    />
                    {formik.touched.url && formik.errors.url && (
                      <Typography
                        className="errorMessage"
                        sx={{
                          margin: "0 !important",
                        }}
                      >
                        <sup>*</sup>
                        {formik.errors.url}
                      </Typography>
                    )}
                  </Box>
                  <Box className="webhookfield">
                    <InputLabel className="formLabel">
                      {MESSAGES.SETTINGS.WEBHOOKS.CREATE.DESCRIPTION_LABEL}
                    </InputLabel>
                    <Input
                      type="text"
                      placeholder={
                        MESSAGES.SETTINGS.WEBHOOKS.CREATE
                          .DESCRIPTION_PLACEHOLDER
                      }
                      autoComplete="off"
                      name="description"
                      onChange={formik.handleChange}
                      className={
                        formik.errors.url && formik.touched.description
                          ? "invalid formInput"
                          : "formInput"
                      }
                      onBlur={
                        realTimeValidation ? formik.handleBlur : undefined
                      }
                      value={formik.values.description}
                      onKeyDown={
                        realTimeValidation ? formik.handleBlur : undefined
                      }
                    />
                    {formik.touched.description &&
                      formik.errors.description && (
                        <Typography
                          className="errorMessage"
                          sx={{
                            margin: "0 !important",
                          }}
                        >
                          <sup>*</sup>
                          {formik.errors.description}
                        </Typography>
                      )}
                  </Box>
                </Box>
                <Divider className="fieldDivider" />
                <Box className="headerFieldsWrapper">
                  <Box className="headerfield">
                    <InputLabel className="formLabel">
                      {MESSAGES.SETTINGS.WEBHOOKS.CREATE.CUSTOM_HEADERS_LABEL}
                      <span>
                        <Tooltip
                          title={
                            <Typography className="info-text">
                              {MESSAGES.SETTINGS.WEBHOOKS.CREATE.HEADERS_INFO}
                            </Typography>
                          }
                          arrow
                          PopperProps={{
                            style: {
                              width: "240px",
                              height: "113px",
                            },
                          }}
                          placement="bottom-start"
                        >
                          <img src={Info} alt="info" />
                        </Tooltip>
                      </span>
                    </InputLabel>
                    <Input
                      type="text"
                      placeholder={
                        MESSAGES.SETTINGS.WEBHOOKS.CREATE
                          .CUSTOM_HEADERS_PLACEHOLDER
                      }
                      name="headers"
                      autoComplete="off"
                      onChange={formik.handleChange}
                      className={
                        formik.errors.headers && formik.touched.headers
                          ? "invalid formInput"
                          : "formInput"
                      }
                      onBlur={
                        realTimeValidation ? formik.handleBlur : undefined
                      }
                      value={formik.values.headers}
                      onKeyDown={
                        realTimeValidation ? formik.handleBlur : undefined
                      }
                    />
                    {formik.touched.headers && formik.errors.headers && (
                      <Typography
                        className="errorMessage"
                        sx={{
                          margin: "0 !important",
                        }}
                      >
                        <sup>*</sup>
                        {formik.errors.headers}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Divider className="fieldDivider" />
                <Box className="eventFieldsWrapper">
                  <Box className="eventField">
                    <InputLabel className="formLabel">
                      {MESSAGES.SETTINGS.WEBHOOKS.CREATE.EVENT_TYPE_LABEL}
                      <sup>*</sup>
                    </InputLabel>
                    <div>
                      <MultiSelect
                        options={eventOptions}
                        selectedValue={selectedEvent}
                        setSelectedValue={setSelectedEvent}
                        simpleSelect={true}
                        placeHolderText={
                          MESSAGES.SETTINGS.WEBHOOKS.CREATE.EVENT_PLACEHOLDER
                        }
                        component="editwebhook"
                      />
                      {eventsError && (
                        <Typography
                          className="errorMessage"
                          sx={{
                            marginTop: "8px !important",
                          }}
                        >
                          <sup>*</sup>
                          {MESSAGES.SETTINGS.WEBHOOKS.CREATE.EVENTS_VALIDATION}
                        </Typography>
                      )}
                    </div>
                  </Box>
                </Box>
                <Divider className="fieldDivider" />
                <Box className="requestBody">
                  <Typography>
                    {MESSAGES.SETTINGS.WEBHOOKS.CREATE.EVENT_TYPE_LABEL}
                  </Typography>
                  <Box className="createOrderObjectWrapper">
                    <pre>
                      {currentEvent &&
                        selectedEvent.length >= 0 &&
                        JSON?.stringify(
                          currentEvent?.example_request_payload,
                          null,
                          2
                        )}
                    </pre>
                  </Box>
                </Box>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box className="createWebhookFooterBtns">
                    <Button onClick={() => navigate("/settings/webhooks")}>
                      {MESSAGES.SETTINGS.WEBHOOKS.CREATE.CANCEL_BUTTON}
                    </Button>
                    <Button
                      className="btnSave"
                      type="submit"
                      onClick={() => validateForm(formik)}
                    >
                      {loading ? (
                        <CircularProgress
                          sx={{
                            color: "white",
                            width: "25px !important",
                            height: "25px !important",
                          }}
                        />
                      ) : (
                        MESSAGES.SETTINGS.WEBHOOKS.CREATE.SUBMIT_BUTTON
                      )}
                    </Button>
                  </Box>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <ActionModal
        openModal={actionWebhookModal}
        handleCloseModal={() => setActionWebhookModal(false)}
        selectedWebhook={selectedWebhook}
      />
      <DeleteWebhookModal
        openModal={deleteWebhookModal}
        handleCloseModal={() => setDeleteWebhookModal(false)}
        selectedWebhook={webhookId}
      />
    </Box>
  );
};

export default CreateOrEditWebhook;
