import React, { useEffect, useState } from "react";

// Hooks
import { useSelector } from "react-redux";

//Utils
import {
  DEFAULT_PAGE_SIZES,
  DEFAULT_PAGINATION,
} from "../../../../utils/constants";
import { MESSAGES } from "../../../../utils/message";

// custom hook
import useScreenWidth from "../../../../hooks/useScreenWidth";

// Components
import Loader from "../../../General/Loader";
import ImpersonateModal from "../ImpersonationModal";

// MUI Table
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Pagination,
  PaginationItem,
  Select,
  MenuItem,
  Typography,
  Tooltip,
  Stack,
  IconButton,
  Menu,
} from "@mui/material";

// Icons
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import UserIcon from "../../../../assets/images/admin/user.svg";
import FundsIcon from "../../../../assets/images/admin/funds.svg";
import DisableIcon from "../../../../assets/images/admin/disable.svg";
import EnableIcon from "../../../../assets/images/webhooks/checkmark.svg";
import NoContact from "../../../../assets/images/contacts/no-contact.svg";

// Styles
import "./styles.scss";
import { stringLength } from "../../../../utils/helperFunctions";
import { displayTooltip } from "../../../../hooks/displayTooltip";

const UsersTable = ({
  pagination,
  updatePagination,
  setOpenActionModal,
  selectedUser,
  setSelectedUser,
  setOpenFundsModal,
  searchApplied
}) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [openImpersonationModal, setImpersonationModal] = useState(false);
  const pageSizes = DEFAULT_PAGE_SIZES;

  const rows = useSelector((state) => state.adminUsersReducers.users);
  const rowsCount = useSelector((state) => state.adminUsersReducers.rowsCount);
  const to = useSelector((state) => state.adminUsersReducers.to);
  const from = useSelector((state) => state.adminUsersReducers.from);
  const lastPage = useSelector(
    (state) => state.adminUsersReducers.lastPage || 1
  );
  const currentPage = useSelector(
    (state) => state.adminUsersReducers.currentPage || 1
  );
  const perPage = useSelector(
    (state) => state.adminUsersReducers.perPage || DEFAULT_PAGINATION.pageSize
  );

  const isLoading = useSelector((state) => state.adminUsersReducers.loading);

  const currentUser = useSelector((state) => state.userReducers.user);

  const handleChangePage = (event, newPage) => {
    updatePagination({
      page: newPage,
      pageSize: pagination.pageSize,
    });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [activeRowId, setActiveRowId] = useState(null);
  const isScreen = useScreenWidth();
  const isTooltip = displayTooltip(2600);

  const columns = [
    {
      field: "id",
      headerName: "User ID",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1550 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.id || " -"}</Typography>
        </Box>
      ),
    },
    {
      field: "ownerId",
      headerName: "Account ID",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1550 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.ownerId || " -"}</Typography>
        </Box>
      ),
    },
    {
      field: "company",
      headerName: "Company",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1550 ? 1 : 0,
      renderCell: (params) =>
        stringLength(`${params.row.company}`, 15) ? (
          <Tooltip title={params.row.company}>
            <Box className="addressWrapper">
              <Typography>{params.row.company || " -"}</Typography>
            </Box>
          </Tooltip>
        ) : (
          <Box className="addressWrapper">
            <Typography>{params.row.company || " -"}</Typography>
          </Box>
        ),
    },
    {
      field: "name",
      headerName: "User Name",
      type: "number",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1550 ? 1 : 0,
      renderCell: (params) =>
        stringLength(`${params.row.name}`, 20) ? (
          <Tooltip title={params.row.name}>
            <Box className="addressWrapper">
              <Typography>{params.row.name || " -"}</Typography>
            </Box>
          </Tooltip>
        ) : (
          <Box className="addressWrapper">
            <Typography>{params.row.name || " -"}</Typography>
          </Box>
        ),
    },
    {
      field: "email",
      headerName: "Email",
      width: 150,
      height: 500,
      sortable: false,
      flex: window.innerWidth >= 1550 ? 1 : 0,
      renderCell: (params) =>
        stringLength(`${params.row.email}`, 13) && !isTooltip ? (
          <Tooltip title={params.row.email}>
            <Box className="emailWrapper">
              <Typography>{params.row.email || " -"}</Typography>
            </Box>
          </Tooltip>
        ) : (
          <Box className="emailWrapper">
            <Typography>{params.row.email || " -"}</Typography>
          </Box>
        ),
    },
    {
      field: "role",
      headerName: "Role",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1550 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.role || " -"}</Typography>
        </Box>
      ),
    },
    {
      field: "plan",
      headerName: "Plan",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1550 ? 1 : 0,
      renderCell: (params) =>
        stringLength(`${params.row.plan}`, 20) ? (
          <Tooltip title={params.row.plan}>
            <Box className="addressWrapper">
              <Typography>{params.row.plan || " -"}</Typography>
            </Box>
          </Tooltip>
        ) : (
          <Box className="addressWrapper">
            <Typography>{params.row.plan || " -"}</Typography>
          </Box>
        ),
    },
    {
      field: "orders",
      headerName: "No of Orders",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1550 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.orders || " -"}</Typography>
        </Box>
      ),
    },
    {
      field: "revenue",
      headerName: "Revenue",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1550 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.revenue || " -"}</Typography>
        </Box>
      ),
    },
    {
      field: "signUpDate",
      headerName: "Sign Up Date",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1550 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.signUpDate || " -"}</Typography>
        </Box>
      ),
    },
    {
      field: "updatedAt",
      headerName: "Last Seen",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1550 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.updatedAt || " -"}</Typography>
        </Box>
      ),
    },
    {
      field: "Action",
      headerName: "Action",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1550 ? 1 : 0,
      renderCell: (params) => (
        <>
          {isScreen ? (
            <Box className="actionsWrapper">
              {currentUser.id !== params.row.id &&
                !params.row.isSystemAdmin && (
                  <>
                    <Tooltip
                      title={params?.row?.status === "Invited" ? "Awaiting signup" : "Impersonate"}
                      className={
                        params?.row?.status === "Invited" ? "disabled" : ""
                      }
                    >
                      <img
                        onClick={() => {
                          if (params?.row?.status !== "Invited") {
                            setSelectedUser(params.row);
                            setImpersonationModal(true);
                          }
                        }}
                        src={UserIcon}
                        alt="icon"
                      />
                    </Tooltip>
                    <Tooltip title="Add Funds">
                      <img
                        src={FundsIcon}
                        alt="icon"
                        onClick={() => {
                          setSelectedUser(params.row), setOpenFundsModal(true);
                        }}
                      />
                    </Tooltip>
                    <Tooltip
                      title={
                        params.row.status === "Blocked"
                          ? "Enable Account"
                          : "Disable Account"
                      }
                      onClick={() => {
                        setSelectedUser(params.row), setOpenActionModal(true);
                      }}
                    >
                      <img
                        src={
                          params.row.status === "Blocked"
                            ? EnableIcon
                            : DisableIcon
                        }
                        alt="Cancel"
                      />
                    </Tooltip>
                  </>
                )}
            </Box>
          ) : (
            <Box>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={(event) => {
                  setActiveRowId(params.row); // Set active row ID when the menu is opened
                  handleClick(event);
                }}
              >
                <MoreVertIcon
                  sx={{
                    color:
                      activeRowId?.id === params.row.id && anchorEl
                        ? "#ed5c2f"
                        : "inherit",
                  }}
                />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxWidth: "150px",
                  },
                  className: "usersActionDropdown",
                }}
              >
                {activeRowId?.status !== "Invited" && (
                  <MenuItem
                    onClick={() => {
                      setSelectedUser(activeRowId);
                      setImpersonationModal(true);
                      handleClose();
                    }}
                  >
                    <img src={UserIcon} alt="impersonate" />
                    Impersonate
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    setSelectedUser(activeRowId);
                    setOpenFundsModal(true);
                    handleClose();
                  }}
                >
                  <img src={FundsIcon} alt="funds" />
                  Add Funds
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setSelectedUser(activeRowId);
                    setOpenActionModal(true);
                    handleClose();
                  }}
                >
                  <img
                    src={
                      activeRowId?.status === "Blocked"
                        ? EnableIcon
                        : DisableIcon
                    }
                    alt="enable"
                  />
                  {activeRowId?.status === "Blocked"
                    ? "Enable Account"
                    : "Disable Account"}
                </MenuItem>
              </Menu>
            </Box>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <div
        style={{
          marginTop: "30px",
          width: "100%",
        }}
        className="userAdmin"
      >
        <DataGrid
          rows={rows}
          columns={columns}
          loading={isLoading}
          rowCount={rowsCount}
          pagination={pagination}
          paginationMode="server"
          hideFooterSelectedRowCount
          hideFooterPagination
          rowSelection={false}
          getRowId={(row) => row.key}
          disableColumnMenu={true}
          className="userAdminTable"
          slots={{
            noRowsOverlay: () => (
              <Stack className="noRowsTextContacts">
                <img src={NoContact} alt="NoContact" />
                {`${MESSAGES.NO_RESULTS_TEXT} ${!searchApplied ? "filters" : "search"}`}
              </Stack>
            ),
            noResultsOverlay: () => (
              <Stack className="noRowsTextContacts">
                <img src={NoContact} alt="NoContact" />
                {MESSAGES.NO_ROWS_TEXT}
              </Stack>
            ),
            loadingOverlay: Loader,
          }}
        />
        <Box className="paginationWrapper">
          <Pagination
            count={lastPage}
            variant="outlined"
            shape="rounded"
            onChange={handleChangePage}
            page={currentPage}
            renderItem={(item) => (
              <PaginationItem
                slots={{
                  previous: ArrowBackIosNewIcon,
                  next: ArrowForwardIosIcon,
                }}
                {...item}
              />
            )}
          />
          <Select
            className={
              perPage >= 100 ? `pageSelect pageSelectChange` : `pageSelect`
            }
            value={perPage}
          >
            {pageSizes.map((pageSize) => {
              return (
                <MenuItem
                  key={pageSize}
                  value={pageSize}
                  onClick={() => {
                    updatePagination({
                      page: 0,
                      pageSize: pageSize,
                    });
                  }}
                >
                  {pageSize}
                </MenuItem>
              );
            })}
          </Select>

          <Typography>
            Showing {rowsCount ? from : 0} to {rowsCount < to ? rowsCount : to}{" "}
            of {rowsCount} results
          </Typography>
        </Box>
      </div>
      <ImpersonateModal
        open={openImpersonationModal}
        handleClose={() => setImpersonationModal(false)}
        selectedUser={selectedUser}
      />
    </>
  );
};

export default UsersTable;
