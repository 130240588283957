import React, { useCallback, useEffect, useState } from "react";

//hooks
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//actions
import {
  fetchOrderContactDetails,
  removeContact,
  fetchOrderOverviewDetails,
} from "../../../../../redux/actions/orders-actions";
import {
  success,
  failure,
} from "../../../../../redux/actions/snackbar-actions";

//utils
import {
  DEFAULT_PAGE_SIZES,
  DEFAULT_PAGINATION,
} from "../../../../../utils/constants";

// Mui Components
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Pagination,
  PaginationItem,
  Select,
  MenuItem,
  Typography,
  Tooltip,
  Stack,
  Menu,
  Checkbox,
  IconButton,
} from "@mui/material";
// Icons
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CancelDisabled from "../../../../../assets/images/orders/cancelOrderDisabled.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DoneIcon from "@mui/icons-material/Done";
import RemoveIcon from "@mui/icons-material/Remove";

// images
import Remove from "../../../../../assets/images/orders/remove.svg";
import CancelOrderIcon from "../../../../../assets/images/orders/cancelOrder.svg";

import ConfirmDialog from "../../../ConfirmDialog/ConfirmDialog";

// styles
import "./styles.scss";

// Custom Loader
import Loader from "../../../../General/Loader";
import { MESSAGES } from "../../../../../utils/message";
import {
  doesItFallInCancellationPeriod,
  dateFormat,
} from "../../../../../utils/date-format";
import { stringLength } from "../../../../../utils/helperFunctions";

/**
 * TemplateListing Component
 * Displays a list of templates with various actions.
 * @returns {JSX.Element} The data grid component showing list templates.
 */
const ContactListing = ({ setSelectedRows, selectedRows }) => {
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);

  // bullk actions
  const [bulkSelect, setBulkSelect] = useState(false);
  const [removeBulkSelect, setRemoveBulkSelect] = useState(false);
  const [rows, setRows] = useState([]);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const search = useSelector(
    (state) => state.ordersReducers.detailContacts.search
  );
  const contacts = useSelector((state) => state.ordersReducers.detailContacts);

  const detail = useSelector((state) => state.ordersReducers.detailOverview);

  const fetchedRows =
    useSelector((state) => state.ordersReducers.detailContacts.list) || [];
  const to = useSelector((state) => state.ordersReducers.detailContacts.to);
  const from = useSelector((state) => state.ordersReducers.detailContacts.from);
  const lastPage = useSelector(
    (state) => state.ordersReducers.detailContacts.lastPage || 1
  );
  const perPage =
    useSelector((state) => state.ordersReducers.detailContacts.perPage) ||
    DEFAULT_PAGINATION.pageSize;

  const currentPage = useSelector(
    (state) => state.ordersReducers.detailContacts.currentPage || 1
  );
  const rowsCount = useSelector(
    (state) => state.ordersReducers.detailContacts.rowsCount
  );

  const [dialog, setDialog] = useState({
    open: false,
    message: "",
    loading: false,
    currentAction: null,
    params: null,
  });

  const dispatch = useDispatch();

  const pageSizes = DEFAULT_PAGE_SIZES;

  const { id } = useParams();

  const getAllContacts = () => {
    dispatch(fetchOrderContactDetails({ id, pagination, search }));
  };

  const handleCloseDialog = () => {
    setDialog({
      open: false,
      message: "",
      loading: false,
      params: null,
      currentAction: null,
    });
  };

  const handleOk = async () => {
    setDialog((prev) => ({ ...prev, loading: true }));
    if (dialog.currentAction === "remove") {
      const payload = {
        orderId: dialog.params.row.orderId,
        contactId: dialog.params.row.contactId,
      };
      const response = await dispatch(removeContact(payload));
      if (response.status === 200) {
        dispatch(success(response.data.message));
        getAllContacts();
      } else {
        dispatch(failure(response.data.message));
      }
    }
    setDialog((prev) => ({ ...prev, loading: false, open: false }));
  };

  const handleCancelOrder = async (params) => {
    setDialog({
      open: true,
      message: "Are you sure you want to remove this contact?",
      loading: false,
      currentAction: "remove",
      params: params,
      successButtonName: "Yes",
      subMessage: "This action cannot be undone. Please proceed with caution.",
      heading: "Remove Contact?",
      icon: CancelOrderIcon,
    });
  };

  const handleChangePage = (value, type) => {
    if (type == "pageSize") {
      setPagination({
        page: pagination.page,
        pageSize: value,
      });
    } else {
      setPagination({
        page: value,
        pageSize: pagination.pageSize,
      });
    }
  };

  useEffect(() => {
    if (!search) {
      getAllContacts();
    }

    dispatch(fetchOrderOverviewDetails({ id }));

    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    getAllContacts();
  }, [pagination]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectAll = () => {
    setBulkSelect(true);
    const updatedRows = rows.map((contact) => ({ ...contact, checked: true }));
    setRows(updatedRows);
    setSelectedRows([-1]);
  };

  const selectVisble = () => {
    setBulkSelect(true);
    const updatedRows = rows.map((contact) => ({ ...contact, checked: true }));
    setRows(updatedRows);

    const selectedRowIds = updatedRows.map((contact) => contact.contactId);
    setSelectedRows(selectedRowIds);
  };

  const selectNone = () => {
    setBulkSelect(false);
    setRemoveBulkSelect(false);
    const updatedRows = rows.map((contact) => ({ ...contact, checked: false }));
    setRows(updatedRows);
    setSelectedRows([]);
  };

  const selectRow = (event, selectedRow) => {
    event.stopPropagation();

    // Update the selectedRow object directly in the rows array
    const updatedRows = rows.map((row) =>
      row.id === selectedRow.id ? { ...row, checked: !row.checked } : row
    );

    // If the user selects all contacts and then removes a single contact, update the selected rows array accordingly.
    if (selectedRows[0] === -1) {
      const filteredRows = rows.map((row) =>
        row.id === selectedRow.id
          ? { ...row, checked: !row.checked }
          : { ...row, checked: true }
      );

      const filteredRowsIds = filteredRows
        .map((contact) => contact.checked === true && contact.contactId)
        .filter(Boolean);

      setSelectedRows(filteredRowsIds);
      setRows(updatedRows);
      setRemoveBulkSelect(true);
      return;
    }

    setRows(updatedRows);

    const selectedRowIds = selectedRows.includes(selectedRow.contactId)
      ? selectedRows.filter((id) => id !== selectedRow.contactId) // Remove the ID if already selected
      : [...selectedRows, selectedRow.contactId]; // Add the ID if not selected

    // Update the selectedRows state with the new array
    setSelectedRows(selectedRowIds);

    setRemoveBulkSelect(true);
  };

  const bulkSelection = () => {
    const newBulkSelect = !bulkSelect;
    setBulkSelect(newBulkSelect);

    const updatedRows = rows.map((contact) => ({
      ...contact,
      checked: newBulkSelect,
    }));
    setRows(updatedRows);

    const selectedRowIds = updatedRows.map((contact) => contact.contactId);
    newBulkSelect ? setSelectedRows(selectedRowIds) : setSelectedRows([]);
  };

  const removeBulkSelection = () => {
    const newBulkSelect = !removeBulkSelect;
    setRemoveBulkSelect(newBulkSelect);
    setBulkSelect(newBulkSelect);

    const updatedRows = rows.map((contact) => ({
      ...contact,
      checked: newBulkSelect,
    }));
    setRows(updatedRows);
    setSelectedRows([]);
  };

  const columns = [
    {
      field: " ",
      headerName: (
        <Box className="checkboxHeader">
          {removeBulkSelect ? (
            <Checkbox
              className="bulkCheck"
              icon={<Box className="bulkNotchecked" />}
              checkedIcon={<RemoveIcon />}
              checked={removeBulkSelect}
              onClick={removeBulkSelection}
            />
          ) : (
            <Checkbox
              className="bulkCheck"
              icon={<Box className="bulkNotchecked" />}
              checkedIcon={<DoneIcon />}
              checked={bulkSelect}
              onClick={bulkSelection}
            />
          )}
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            disableRipple
            className="bulkActionsDropIcon"
          >
            <KeyboardArrowDownIcon
              sx={{
                color: anchorEl ? "#ed5c2f" : "inherit",
                transform: anchorEl && "rotate(180deg)",
              }}
            />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            transformOrigin={{ horizontal: "left", vertical: "top" }}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            MenuListProps={{
              className: "bulkMenuOptions",
            }}
            sx={{
              "& .MuiMenu-paper": {
                top: "230px !important",
                "@media (max-width: 900px)": {
                  top: "290px !important",
                },
              },
            }}
          >
            <MenuItem
              onClick={selectAll}
              className={selectedRows[0] === -1 ? "highlight" : ""}
            >
              {MESSAGES.ORDERS.DETAIL.CONTACTS.TABLE.SELECT_ALL_ITEMS} (
              {rowsCount})
            </MenuItem>
            <MenuItem onClick={selectNone}>
              {MESSAGES.ORDERS.DETAIL.CONTACTS.TABLE.SELECT_NONE}
            </MenuItem>
            <MenuItem onClick={selectVisble}>
              {MESSAGES.ORDERS.DETAIL.CONTACTS.TABLE.SELECT_VISIBLE_ITEMS}
            </MenuItem>
          </Menu>
        </Box>
      ),
      width: 75,
      sortable: false,
      renderCell: (params) => (
        <Box className="checkboxRow">
          <Checkbox
            onClick={(event) => selectRow(event, params.row)}
            className="bulkCheck"
            icon={<Box className="bulkNotchecked" />}
            checkedIcon={<DoneIcon />}
            checked={params.row.checked}
          />
        </Box>
      ),
    },
    {
      field: "name",
      headerName: "Full Name",
      width: 205,
      sortable: false,
      flex: window.innerWidth > 1380 ? 1 : 0,
      renderCell: (params) =>
        stringLength(`${params?.row?.recipient?.fullName}`) ? (
          <Tooltip title={params?.row?.recipient?.fullName}>
            <Box className="valWrapper">
              <Typography>{params?.row?.recipient?.fullName || "-"}</Typography>
            </Box>
          </Tooltip>
        ) : (
          <Box className="valWrapper">
            <Typography>{params?.row?.recipient?.fullName || "-"}</Typography>
          </Box>
        ),
    },
    {
      field: "company_name",
      headerName: "Company Name",
      type: "number",
      width: 205,
      sortable: false,
      flex: window.innerWidth > 1380 ? 1 : 0,
      renderCell: (params) =>
        stringLength(`${params?.row?.recipient?.companyName}`, 25) ? (
          <Tooltip title={params?.row?.recipient?.companyName}>
            <Box className="valWrapper">
              <Typography>{params?.row?.recipient?.companyName || "-"}</Typography>
            </Box>
          </Tooltip>
        ) : (
          <Box className="valWrapper">
            <Typography>{params?.row?.recipient?.companyName || "-"}</Typography>
          </Box>
        ),
    },
    {
      field: "address",
      headerName: "Full Address",
      width: 205,
      sortable: false,
      flex: window.innerWidth > 1380 ? 1 : 0,
      renderCell: (params) =>
        stringLength(
          `${params?.row?.recipient?.address1}` +
            `${params?.row?.recipient?.city}` +
            `${params?.row?.recipient?.state}` +
            `${params?.row?.recipient?.zip}`,
          30
        ) ? (
          <Tooltip
            title={`${params?.row?.recipient?.address1}, ${params?.row?.recipient?.city}, ${params?.row?.recipient?.state}, ${params?.row?.recipient?.zip}`}
          >
            <Box className="addressWrapper">
              <Typography>
                {params?.row?.recipient?.address1}, {params?.row?.recipient?.city},
                {params?.row?.recipient?.state}, {params?.row?.recipient?.zip}
              </Typography>
            </Box>
          </Tooltip>
        ) : (
          <Box className="addressWrapper">
            <Typography>
              {params?.row?.recipient?.address1}, {params?.row?.recipient?.city},
              {params?.row?.recipient?.state}, {params?.row?.recipient?.zip}
            </Typography>
          </Box>
        ),
    },
    {
      field: "status",
      headerName: "Address Status",
      width: 205,
      sortable: false,
      flex: window.innerWidth > 1380 ? 1 : 0,
      renderCell: (params) => (
        <Box className="valWrapper">
          <Typography>{params?.row?.recipient?.addressStatus || "-"}</Typography>
        </Box>
      ),
    },
    {
      field: "mailed_status",
      headerName: "Mailed Status",
      width: 205,
      sortable: false,
      flex: window.innerWidth > 1380 ? 1 : 0,
      renderCell: (params) => (
        <Box className="valWrapper">
          <Typography>
            {params?.row?.status || "-"}
          </Typography>
        </Box>
      ),
    },
    {
      field: "deliveredAt",
      headerName: "Delivered Date",
      width: 205,
      sortable: false,
      flex: window.innerWidth > 1380 ? 1 : 0,
      renderCell: (params) => (
        <Tooltip title={params?.row?.recipient?.lastMailedDate}>
        <Box className="valWrapper">
          <Typography>{params?.row?.recipient?.lastMailedDate || "-"}</Typography>
        </Box>
        </Tooltip>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 205,
      sortable: false,
      flex: window.innerWidth > 1380 ? 1 : 0,
      renderCell: (params) => (
        <Box className="actionsWrapper">
          <Tooltip
            title={
              doesItFallInCancellationPeriod(
                dateFormat(detail?.stepper?.campaign?.deliveryDate, true)
              ) &&
              (detail?.stepper?.status == "On Hold" ||
                detail?.stepper?.status == "Scheduled")
                ? "Remove Contact"
                : "Cancellation window has expired"
            }
          >
            {doesItFallInCancellationPeriod(
              dateFormat(detail?.stepper?.campaign?.deliveryDate, true)
            ) &&
            (detail.stepper.status == "On Hold" ||
              detail.stepper.status == "Scheduled") ? (
              <img
                src={Remove}
                alt="remove"
                onClick={() => handleCancelOrder(params)}
              />
            ) : (
              <img src={CancelDisabled} alt="Cancel" />
            )}
          </Tooltip>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    setSelectedRows([]);
  }, [currentPage, perPage]);

  useEffect(() => {
    !selectedRows.length && setRemoveBulkSelect(false);
    (rows.length && selectedRows.length === rows.length) ||
    selectedRows[0] === -1
      ? (setBulkSelect(true), setRemoveBulkSelect(false))
      : setBulkSelect(false);
  }, [selectedRows, rows]);

  useEffect(() => {
    setRows(fetchedRows);
  }, [fetchedRows]);

  return (
    <div
      style={{
        marginTop: "25px",
        width: "100%",
      }}
      className="orderDetailsInfoTable"
    >
      <DataGrid
        rows={rows}
        columns={columns}
        rowCount={rowsCount}
        paginationMode="server"
        hideFooterSelectedRowCount
        hideFooterPagination
        rowSelection={false}
        getRowId={(row) => row.id}
        disableColumnMenu={true}
        className="orderDetailsTable"
        loading={contacts.loading}
        components={{
          NoRowsOverlay: () => (
            <Stack className="noRowsText">
              {MESSAGES.ORDERS.DETAIL.CONTACTS.TABLE.NO_RESULT}
            </Stack>
          ),
          loadingOverlay: Loader,
        }}
      />
      <Box className="productTablePagination">
        <Pagination
          count={lastPage}
          variant="outlined"
          shape="rounded"
          onChange={(e, newVal) => handleChangePage(newVal, "page")}
          page={currentPage}
          renderItem={(item) => (
            <PaginationItem
              slots={{
                previous: ArrowBackIosNewIcon,
                next: ArrowForwardIosIcon,
              }}
              {...item}
            />
          )}
        />
        <Select
          className={
            pagination.pageSize >= 100
              ? `pageSelect pageSelectChange`
              : `pageSelect`
          }
          value={pagination.pageSize}
        >
          {pageSizes.map((pageSize) => {
            return (
              <MenuItem
                key={pageSize}
                value={pageSize}
                onClick={() => {
                  handleChangePage(pageSize, "pageSize");
                }}
              >
                {pageSize}
              </MenuItem>
            );
          })}
        </Select>
        <Typography>
          Showing {rowsCount ? from : 0} to{" "}
          {rowsCount < to ? rows?.length + from - 1 : to} of {rowsCount} results
        </Typography>
      </Box>
      {dialog.open && (
        <ConfirmDialog
          dialog={dialog}
          open={dialog.open}
          loading={dialog.loading}
          handleClose={handleCloseDialog}
          handleOk={handleOk}
        />
      )}
    </div>
  );
};

export default ContactListing;
